import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = ({ children }) => {
	
   function hasJWT() {
       let flag = false;
 
       //check user has JWT token
       localStorage.getItem("accessToken") ? flag=true : flag=false
      
       return flag
   }	
	
  const { user } = useAuth();
  if (!user || !hasJWT()) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};