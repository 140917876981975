import React, { useState, useEffect } from 'react';
import UserService from '../services/UserService';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import AppSidebar from '../components/AppSidebar';
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import axios from "axios";
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner
} from '@coreui/react'

import { Nav } from 'react-bootstrap';


const UserComponent = (props) => {

const [state, setState] = useState({
            users:[]
        });  
		
const [isLoading, setIsLoading] = useState(false);



	  useEffect(() => {
		 // console.log(location);
		 // setState({ users: []});
		  
		  UserService.getUsers().then((response) => {
            setState({ users: response.data})
			
			 console.log('hiii',response.data);
			
        });
		    }, []); //location.state

    
	
	
	
	const remove= (id) => {
		//console.log(id);
        axios.delete(`http://localhost:8080/api/test/users/${id}`, 
        
        {headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
							 } 
					}
    ).then(() => {
        let updatedClients = [...state.users].filter(i => i.id !== id);
        setState({users: updatedClients});
		
		
		
    });
	}



   
        return (
            <div>
			
			{isLoading ? <CSpinner/>: null}
			<CCard className="mb-4">
            <CCardHeader>
			             <div className="row">
						 <div className = "text-center d-inline-block col-md-8" >
			             <h5 className = "text-center d-inline-block">Users</h5>
					     </div>
						 
						 <div className="text-end col-md-4">
			             <Button size="sm" color="success" tag={Link} 
										   to={"/users_create/"+''} 
										   key={""}
										   className = "text-end"
										   >Create</Button> 
                       										   
						 </div>	
                         </div>						 
			</CCardHeader>			
			<CCardBody>
			<CRow>
            <CCol xs={12} md={12} xl={12}>
			    
				<PaginatedItems items={state.users} itemsPerPage={5}  removefun={(a) =>remove(a)} />
            
			</CCol>
			</CRow>	
            </CCardBody>            
			</CCard>
			
            </div>

        )
    
}


function Items(props) {
	
	console.log('currentItems',props);
	
  return (
    <>
	<table className = "table table-striped">
                    <thead>
                        <tr>

                            <td> Id</td>
                            <td> Username</td>
                            <td> Email</td>
                            <td> Password</td>
     						<td> Actions</td>
                        </tr>

                    </thead>
	<tbody>
      {props.currentItems &&
        props.currentItems.map((item) => (
          <tr key = {item.id}>
                                     <td> {item.id}</td>   
                                     <td> {item.username}</td>   
                                     <td> {item.email}</td>   
                                     <td> {item.password}</td> 

									<td>
										<ButtonGroup>
											<Button size="sm" color="primary" tag={Link} 
											to={"/users/" + item.id} 
											key={item.id}											
											>Edit</Button>
											
											<Button size="sm" color="danger" onClick={() => props.removefun(item.id)}>Delete</Button>
											
										</ButtonGroup>
									</td>				
                                </tr>
        ))}
		</tbody>
		</table>
		
    </>
  );
}

function PaginatedItems(pros) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
	if(pros.items.length >= 0 ){  
    // Fetch items from another resources.
    const endOffset = itemOffset + pros.itemsPerPage;
	console.log(`Item load`,pros.items,pros);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(pros.items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(pros.items.length / pros.itemsPerPage));
	}
	
  }, [itemOffset, pros.itemsPerPage,pros.items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * pros.itemsPerPage) % pros.items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} removefun={(a) =>pros.removefun(a)} />
	  <nav aria-label="Page navigation example">
      <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={null}
		containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
	    breakClassName="page-item"
        breakLinkClassName="page-link"
      />
	  </nav>
    </>
  );
}

export default UserComponent