import React, { useState, useEffect }  from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../components/AppNavbar';
import AppSidebar from '../components/AppSidebar';
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CAlert
} from '@coreui/react'
import { useForm } from "react-hook-form";

const UserEditComponent = (props) => {
	
	const [formValues, setFormValues] = useState({
	id:"",	
    username: "",
    email: "",
    roles : []
     });
	
	const options = [
					  { label: "ROLE_USER", value: 1 },
					  { label: "ROLE_MODERATOR", value: 2 },
					  { label: "ROLE_ADMIN", value: 3/*, disabled: true*/ },
					];

   let { id } = useParams();
   let tmp_roles = [];   
   const [selected, setSelected] = useState(tmp_roles);
   const [errors, setErrors] = useState({});   
   const navigate = useNavigate();
   
   
	
  let handleSubmit =(e) =>{
	   //alert('submit');
	   e.preventDefault();
	  
       let item = formValues;
           item.roles = selected.map(t => ({ name: t.label,id: t.value }));;
		   
	   if(validate(item)){
		   
	  
	   
		if(item.id != "" ){   
		   
		   
		   console.log(item);
		   
		   axios.put(`http://localhost:8080/api/test/users/`+id , 
				   JSON.stringify(item),
				   {headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
							 } 
					}
			        ).then(response => {
						navigate('/users',{state:{refresh:true}})
						// return response.data
					}).catch(error => {
						 console.log(error.response.data.error)
					});
		  }else{
          
           
		   
		   console.log(item);
		   
		   axios.post(`http://localhost:8080/api/test/users` , 
				   JSON.stringify(item),
				   {headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
							 } 
					}
			        ).then(response => {
						navigate('/users',{state:{refresh:true}})
						// return response.data
					}).catch(error => {
						 console.log(error.response.data.error)
					});	  
			  
		  }
		  /*setTimeout(()=> { 
			navigate('/users',{state:{refresh:true}})
		  }, 2000);*/
	   }
  }
  
   let handleChange = (e) => {
	  
    let name = e.target.name;
    let value = e.target.value;
    formValues[name] = value;
    setFormValues(formValues);
	
  }

   // Load data from server and reinitialize student form
  useEffect(() => {

	  if(id != undefined){
	 
    axios.get(
        'http://localhost:8080/api/test/users/' 
        + id
      )
      .then((res) => {
        const { username, email, id, roles } = res.data;
						
        setFormValues({ username, email, id, roles });
				
        tmp_roles = roles.map(t => ({ label: t.name,value: t.id }));		
		
		/*tmp_roles.forEach((item, index) => {
					  console.log(item,'item');
					  setSelected(item);
					});*/
	    setSelected(tmp_roles);	  
		
      })
      .catch((err) => console.log(err));
	  }
	  
	  }, []);
   

  const validate = (item) =>{
	    let isValid = true;
		let t_errors = {};
		
		if(item.username !== "undefined"){
			
			if(item.username == '' ){
				 isValid = false;
				 t_errors.username ="Please enter Username.";
			}
		}
	  
	    if (item.email !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(item.email)) {
          isValid = false;
		   	  
		   
           t_errors.email ="Please enter valid email address.";		   
		 
        }
      }
	    setErrors(t_errors);
	    return isValid;
  }
   
 useEffect(() => {	
  setErrors(errors);
},[errors])

        console.log("formValues",formValues);
        const item = formValues;
        const title = <h5>{item.id ? 'Edit User' : 'Add User'}</h5>;
		
		

        return <div>
		    
			<CCard className="mb-4">
            <CCardHeader>{title}</CCardHeader>			
			<CCardBody>
			<CRow>
            <CCol xs={12} md={12} xl={12}>
                
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="name">Username</Label>
                        <Input type="text" name="username" id="username" defaultValue={item.username || ''}
                               onChange={handleChange} autoComplete="username"/>
							   
							   {errors.username?<CAlert color="danger" className="p-1 mt-1 d-flex align-items-center">{errors.username}</CAlert>:null}
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" defaultValue={item.email || ''}
                               onChange={handleChange} autoComplete="email"
						   
                               						   
					    />
			{errors.email?<CAlert color="danger" className="p-1 mt-1 d-flex align-items-center">{errors.email}</CAlert>:null}
						
                    </FormGroup>
					
					<FormGroup>
						
						<MultiSelect
							options={options}
							value={selected}
							onChange={setSelected}
							labelledBy="Select"
						  />
					</FormGroup>
					
					
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/users">Cancel</Button>
                    </FormGroup>
					
					
					
                </Form>
			
			</CCol>
			</CRow>	
            </CCardBody>            
			</CCard>
       
		</div>
    
}

export default UserEditComponent;