import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import UserComponent from './components/UserComponent';
import AppNavbar from './components/AppNavbar';
import Home from './components/Home';
import UserEdithComponent from './components/UserEditComponent';
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ProtectedLayout } from "./components/ProtectedLayout";
import ProfilePage from "./components/ProfilePage";
import {LoginPage} from "./components/LoginPage";
import {LogoutPage} from "./components/LogoutPage";
import DashboardPage from "./views/dashboard/Dashboard";
import SwaggerPage from "./views/Swagger/SwaggerPage";
import './scss/style.scss'
import api from "./api";


import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
          <div className="App">
			  <Routes>
				<Route path='/' exact={true} element={<Home/>}/>
				<Route path="/login" element={<LoginPage/>} /> 
				<Route path="/logout" element={<LogoutPage/>} /> 
				
				
				  <Route path="/dashboard" element={<ProtectedLayout />}>
				    <Route path=""  element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
					<Route path="profile"  element={<ProtectedRoute><ProfilePage /></ProtectedRoute>}/>
					
				  </Route>
				  
				  <Route path="/" element={<ProtectedLayout />}>
				  <Route path='users' exact={true} element={<ProtectedRoute><UserComponent/></ProtectedRoute>}/>
				  <Route path='users_create' element={<ProtectedRoute><UserEdithComponent/></ProtectedRoute>}/>
				  <Route path='/users/:id' element={<ProtectedRoute><UserEdithComponent/></ProtectedRoute>}/>
				  <Route path='swagger' exact={true} element={<ProtectedRoute><SwaggerPage/></ProtectedRoute>}/>
				  </Route>
				  
			  </Routes>
		  </div>
        
  );
} 

export default App;
