import React, { Component } from 'react';
import '../App.css';
import AppNavbar from '../components/AppNavbar';
import AppSidebar from '../components/AppSidebar';

import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

class Home extends Component {
    render() {
        return (
            <div>
			
			    <AppSidebar />
			    <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppNavbar/>
				
				<div className="body flex-grow-1 px-3">
                <Container fluid>
				    <Button color="link"><Link to="/login">Login</Link></Button>
                    <Button color="link"><Link to="/users">Users</Link></Button>
					<Button color="link"><Link to="/dashboard">dashboard</Link></Button>
					<Button color="link"><Link to="/dashboard/profile">Profile</Link></Button>
					<Button color="link"><Link to="/logout">Logout</Link></Button>
					
                </Container>
				</div>
				</div>
				
            </div>
        );
    }
}

export default Home;