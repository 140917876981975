import React, {Component} from 'react';
import {Navbar, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom';
import { logo } from '../assets/brand/logo'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AppHeaderDropdown } from './header/index';
// sidebar nav config
import {navStaff, navAdmin} from '../_nav'
import AppSidebar from '../components/AppSidebar';




const AppNavbar = () => {
        
      const dispatch = useDispatch();
      const sidebarShow = useSelector((state) => state.sidebarShow);
  	
  
        /*return <Navbar color="dark" dark expand="md" p-1>
               <NavbarBrand tag={Link} to="/">Home</NavbarBrand>			
               </Navbar>;*/
		return ( 
					 
					<CHeader position="sticky" className="mb-4">
					<CContainer fluid>
						<CHeaderToggler
						  className="ps-1"
						  onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
						>
						  <CIcon icon={cilMenu} size="lg" />
						</CHeaderToggler>
						
						<CHeaderBrand className="mx-auto d-md-none" to="/">
						  <CIcon icon={logo} height={48} alt="Logo" />
						</CHeaderBrand>
						
						 <CHeaderNav className="d-none d-md-flex me-auto">
						  <CNavItem>
							<CNavLink to="/dashboard" component={NavLink}>
							  Dashboard
							</CNavLink>
						  </CNavItem>
						  <CNavItem>
							<CNavLink href="/users">Users</CNavLink>
						  </CNavItem>
						  <CNavItem>
							<CNavLink href="#">Settings</CNavLink>
						  </CNavItem>
						</CHeaderNav>
						
						<CHeaderNav>
						  <CNavItem>
							<CNavLink href="#">
							  <CIcon icon={cilBell} size="lg" />
							</CNavLink>
						  </CNavItem>
						  <CNavItem>
							<CNavLink href="#">
							  <CIcon icon={cilList} size="lg" />
							</CNavLink>
						  </CNavItem>
						  <CNavItem>
							<CNavLink href="#">
							  <CIcon icon={cilEnvelopeOpen} size="lg" />
							</CNavLink>
						  </CNavItem>
						</CHeaderNav>
						
						<CHeaderNav className="ms-3">
						  <AppHeaderDropdown />
						</CHeaderNav>
					
					</CContainer>
						
						 <CHeaderDivider />
						 
						
					
				   </CHeader>
				   
				   );
    
	
	
}

export default AppNavbar