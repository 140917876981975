import axios from 'axios';
 
export const setAuthToken = token => {
   if (token) {
       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	   axios.defaults.headers.common["Cache-Control"] = `no-cache`;
	   axios.defaults.headers.common["Pragma"] = `no-cache`;
	   axios.defaults.headers.common["Expires"] = `0`;
	   
   }
   else
       delete axios.defaults.headers.common["Authorization"];
}