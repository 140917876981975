import React, { useState, useEffect }  from 'react';
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
import {setAuthToken} from '../components/setAuthToken';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const [error,setError]=useState();

  // call this function when you want to authenticate the user
  const login = async (data) => {
	 
	    console.log(data);
		
	    
		
	    const loginPayload = {
		 username: data.email,
		 password: data.password
	   }
	   
      return await axios.post("http://localhost:8080/api/auth/signin", loginPayload)
      .then(response => {
        //get token from response
        const token  =  response.data;
 
        //set JWT token to local
        localStorage.setItem("accessToken", token.accessToken);
		localStorage.setItem("refreshToken", token.refreshToken);
		
 
        //set token to axios common header
        setAuthToken(token.accessToken);
 
		//redirect user to home page
		setUser(data);
        navigate("/dashboard/profile");
		
		}).catch(err => {
			     console.log(err);
				 setError('Invalid Username or Password');
				 throw err;
				  }
				 );
		 
	  
       
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
	localStorage.removeItem("accessToken");
	localStorage.removeItem("refreshToken");
	setAuthToken(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

