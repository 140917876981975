import axios from "axios";
import {setAuthToken} from './components/setAuthToken';
import {useNavigate}  from 'react-router-dom';

const baseUrl = "http://localhost:8080/api";


//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["x-auth-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = localStorage.getItem("refreshToken");
	//let navigate = useNavigate();

		
	if (
		  refreshToken &&	  
		  error.response.status === 401 &&
		  !originalRequest._retry
		) {
			
		  originalRequest._retry = true;
		  
		  return axios
			.post(`${baseUrl}/auth/refreshtoken`, { refreshToken: refreshToken })
			.then((res) => {
			  if (res.status === 200) {
				localStorage.setItem("accessToken", res.data.accessToken);
				localStorage.setItem("refreshToken", res.data.refreshToken);
				setAuthToken(res.data.accessToken);
				console.log("Access token refreshed!");
				return axios(originalRequest);
			  }else{
				  console.log("Access token Error 1 !");
				 // navigate('/');
				  return Promise.reject(error);
			  }
			  
			}).catch(err => {
				 console.log("Access token Error 2 !");
				//navigate('/');
				return Promise.reject(error);
			});
			
			;
		}
		 console.log("Access token Error 3 !");
			
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			setAuthToken(null);
		//navigate('/');
		return Promise.reject(error);
	  
  }
);
//functions to make api calls
const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/auth/login`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}/auth/refreshtoken`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}/auth/logout`, body);
  },
  getProtected: () => {
    return axios.get(`${baseUrl}/protected_resource`);
  },
};
export default api;