import React, { Component } from 'react';
import '../App.css';
import AppNavbar from '../components/AppNavbar';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

class ProfilePage extends Component {
    render() {
        return (
            <div>
                
                <Container fluid>
                    <Button color="link"><Link to="/users">Users</Link></Button>
					<div>Profile Page</div>
                </Container>
            </div>
        );
    }
}

export default ProfilePage;