import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import AppNavbar from '../components/AppNavbar';
import AppSidebar from '../components/AppSidebar';

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div>
	 <AppSidebar />
	 <div className="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppNavbar
        pages={[
          { label: "Settings", path: "settings" },
          { label: "Profile", path: "profile" }
        ]}
      />
	  
	    <div className="body flex-grow-1 px-3">
		{outlet}
		</div>
	 </div>	
    </div>
  );
};